/* You can add global styles to this file, and also import other style files */
@import 'animate.css';

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,700;1,300&display=swap');

*{
    font-family: 'Poppins', sans-serif;
}



body{
    background-color: #F3F5F8;
}


.discontent{
    margin: 0 0 0 0;
  }

.botonMenu{
    color: #316CF5;
    font-weight: 700;
}

h1{
    font-weight: 700;
    font-size: 2em;
}

.header{
    padding:15px;
    overflow: hidden;
    padding-top: 66px;
}

.header h2{
    font-size: 1.3em;
    font-weight: 700;
    margin-bottom: 0;
    padding-top:5px;
    color:white;
}

.pad20{
    padding:20px;
}

.r0{
    margin-right: 0;
}

.azul{
    background-color: #316CF5;
}

.celeste0{
    background-color: #E3EDF7;
}


.celeste{
    background-color: #5D9BEC;
}

.celeste2{
    background-color: #E3EAF9;
}

.verde{
    background-color: #67A18B;
}


.verde2{
    background-color: #497261;
}

.verde50{
    background-color: rgba(73,114,97, 0.5)
}

.salmon{
    background-color: #FB7C85;
}

.rosado{
    background-color: #E54B7D;
}

.naranja{
    background-color: #FB6E52;
}

.naranja2{
    background-color: #FC4A1A;
}

.gris{
    background-color: #656D78;
}

.negro{
    background-color: #121212;
}

.blanco{
    background-color: #FFFFFF;
}

.rojo{
    background-color: #A8201A;
    /* #ED6A5A */
}

.fondoCeleste{
    background-color: #E3EDF7;
}


.moradoDeg{
    background: rgb(69,48,178);
    background: linear-gradient(333deg, rgba(69,48,178,1) 0%, rgba(116,99,228,1) 100%);
}

.rosadoDeg{

    background: rgb(218,110,148);
    background: linear-gradient(333deg, rgba(218,110,148,1) 0%, rgba(218,65,103,1) 100%);
}



.papel{
    background-image: url('assets/images/lined_paper.png');
}

.colorsvg{
    filter:  grayscale(100%) brightness(200%);
}

.amarillo{
    background-color: #FBCE2E;
}
.pointer{
    cursor: pointer;
}

.formu {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.off50{
    width: 50%;
}

.fondoModal {
    background-color: rgba(0, 5, 46, 0.6);
    position: fixed;
    top: 0;
    z-index: 1040;
    height: 100vh;
    width: 100%;
    margin-left: 0;
}

.fondoModal2 {
    background-color: rgba(0, 5, 46, 0.6);
    position: fixed;
    top: 0;
    z-index: 1050;
    height: 100vh;
    width: 100%;
    margin-left: 0;
}

.fondoModal3 {
    background-color: rgba(0, 5, 46, 0.6);
    position: fixed;
    top: 0;
    z-index: 1050;
    height: 100vh;
    width: 100%;
}


.scroll{
    max-height: 90vh;
    overflow: auto;
}

.scroll100{
    max-height: 100vh;
    overflow: auto;
}

td{
    font-size: 0.8em;
}



.caja{
    color:white;
    border-radius: 10px;
    padding:15px 15px 5px;
}

.caja h2{
    font-size:1em;
    font-weight: 700;
}

.caja h1{
    font-size:1.5em;
    font-weight: 300;
}

.subheader{
 
    padding:15px;
}

.subheader h5{
    color:white;
    font-weight: 400;
    margin-bottom:0;
    font-size: 1em;
}


.tachado{
    text-decoration: line-through;
    color:red;
}

 /* Medium devices (tablets, 768px and up) */
 @media (min-width: 768px) { 
  
 }

 
 /* Large devices (desktops, 992px and up) */
@media (min-width: 769px) { 
    .fondoModal {
        margin-left: -160px;
    }
    .header{
        padding-top:10px;
    }

    td{
        font-size: 1em;
    }

    .discontent{
        margin: 0 0 0 -160px;
      }
}

 /* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }

 /* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
